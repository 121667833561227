<template>
  <v-simple-table dense>
    <tbody>
      <tr v-for="(attr, i) in filteredAttributes" :key="i">
        <td>{{ getDescription(attr.attr_code) }}:</td>
        <td v-if="attr.attr_value">{{ attr.attr_value }}</td>
        <td v-else>-</td>
      </tr>

      <tr style="cursor: pointer" v-if="showXgsPonAttrs">
        <td @click="routerTypeDialog = true">
          <v-icon small>mdi-pencil-outline</v-icon>
          {{ getDescription("RTYP") }}
        </td>
        <v-menu
          v-model="routerTypeDialog"
          :close-on-content-click="false"
          min-width="20%"
          max-width="30%"
        >
          <template v-slot:activator="{ on }">
            <td v-on="on">{{ routerType ? routerType : "-" }}</td>
          </template>
          <validation-observer
            ref="obsRtyp"
            v-slot="{ errors, invalid, valid }"
          >
            <v-card>
              <v-card-subtitle> {{ getDescription("RTYP") }}: </v-card-subtitle>
              <v-card-actions>
                <v-spacer />
                <v-select
                  v-model="routerType"
                  name="router_type"
                  :items="routerTypes"
                  itemText="name"
                  itemValue="name"
                  autofocus
                  single-line
                  :clearable="!isMobile"
                  outlined
                  full-width
                />
                <v-spacer />
              </v-card-actions>
              <v-card-actions>
                <v-spacer />
                <v-btn text @click="routerTypeDialog = false">
                  {{ $t("cancel") }}
                </v-btn>
                <v-btn
                  color="primary"
                  @click="
                    $emit('updateRouterType', routerType);
                    routerTypeDialog = false;
                  "
                  text
                >
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </validation-observer>
        </v-menu>
      </tr>

      <tr style="cursor: pointer" v-if="showXgsPonAttrs">
        <td @click="xgsSerialNumberDialog = true">
          <v-icon small>mdi-pencil-outline</v-icon>
          {{ getDescription("XGSSN") }}
        </td>
        <v-menu
          v-model="xgsSerialNumberDialog"
          :close-on-content-click="false"
          min-width="20%"
          max-width="30%"
        >
          <template v-slot:activator="{ on }">
            <td v-on="on">{{ xgsSerialNumber ? xgsSerialNumber : "-" }}</td>
          </template>
          <validation-observer
            ref="obsXgsSn"
            v-slot="{ errors, invalid, valid }"
          >
            <v-card>
              <v-card-subtitle>
                {{ getDescription("XGSSN") }}:
              </v-card-subtitle>
              <v-card-actions>
                <v-spacer />
                <validation-provider
                  vid="xgs_serial_number"
                  rules="max:12"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-text-field
                    v-model="xgsSerialNumber"
                    name="xgs_serial_number"
                    autofocus
                    single-line
                    :clearable="!isMobile"
                    counter="12"
                    maxlength="12"
                    outlined
                    full-width
                  />
                </validation-provider>
                <v-spacer />
              </v-card-actions>
              <v-card-actions>
                <v-spacer />
                <v-btn text @click="xgsSerialNumberDialog = false">
                  {{ $t("cancel") }}
                </v-btn>
                <v-btn
                  color="primary"
                  @click="
                    $emit('updateXgsSerialNumber', xgsSerialNumber);
                    xgsSerialNumberDialog = false;
                  "
                  text
                >
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </validation-observer>
        </v-menu>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<style scoped>
td {
  width: 50%;
}
</style>

<script>
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "ProductAttributes",
  mixins: [isMobile],
  props: {
    attributes: {
      type: Array,
      required: true,
    },
    showXgsPonAttrs: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    routerTypeDialog: false,
    xgsSerialNumberDialog: false,
    routerType: null,
    xgsSerialNumber: null,
    routerTypes: [],
  }),
  computed: {
    filteredAttributes() {
      var that = this;
      return this.attributes.filter(
        (item) =>
          !that.showXgsPonAttrs || !["RTYP", "XGSSN"].includes(item.attr_code)
      );
    },
  },
  watch: {
    attributes: {
      immediate: true,
      handler() {
        for (let i = 0; i < this.attributes.length; i++) {
          if (this.attributes[i].attr_code == "RTYP")
            this.routerType = this.attributes[i].attr_value;
          else if (this.attributes[i].attr_code == "XGSSN")
            this.xgsSerialNumber = this.attributes[i].attr_value;
        }
      },
    },
  },
  methods: {
    getDescription(attr_code) {
      if (
        this.$store.state.attributeTypes != null &&
        this.$store.state.attributeTypes.hasOwnProperty(attr_code)
      ) {
        var attr = this.$store.state.attributeTypes[attr_code];
        var attr_name = "description_" + this.$store.state.locale;
        if (attr.hasOwnProperty(attr_name))
          return attr[attr_name] || attr.description || attr_code;
        return attr.description || attr.attr_code;
      } else return attr_code;
    },
    getAttributeTypes() {
      if (this.$store.state.attributeTypes == null) {
        var that = this;
        that.$http
          .get("products/attributes")
          .then((response) => {
            var attributeTypes = {};
            response.data.forEach(function (attr) {
              attributeTypes[attr.attr_code] = attr;
            });
            this.$store.commit("setAttributeTypes", attributeTypes);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    getRouterTypes() {
      var that = this;
      this.$http
        .get("products/router-types")
        .then((response) => {
          this.routerTypes = response.data;
        })
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        });
    },
  },
  created() {
    this.getAttributeTypes();
    this.getRouterTypes();
  },
};
</script>
